<template>
  <div class="manage-property-tags animatedBox">
    <div class="container fluid">
      <div class="card flat p-3 mb-2">
        <h3 class="mb-1">Manage Tags</h3>
        <p>Manage recommended tags used in property & project</p>
      </div>
      <div class="card">
        <!-- Table -->
        <vue-good-table
          mode="remote"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          :columns="propertyTagsTableColumns"
          :rows="propertyTagsTableData"
          :totalRows="propertyTagsTablePagination.total"
          :pagination-options="{
            enabled: true,
            mode: 'pages',
            perPage: 30,
            perPageDropdown: perPageOptions,
            dropdownAllowAll: false
          }"
          :sort-options="{
            enabled: false
          }"
        >
          <div slot="emptyarea">
            <no-data message="There is no data"></no-data>
          </div>
          <div slot="table-actions" class="p-2">
            <button class="btn main mr-2" @click="openAddTag">
              <i class="fas fa-plus"></i>
            </button>
            <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button>
          </div>
          <!-- Table Buttons -->
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'actions'">
              <button
                class="btn danger"
                @click="deletePropertyTags(props.row.name)"
              >
                <i class="fas fa-trash"></i>
              </button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </div>
    </div>
    <!-- Add Tags modal -->
    <modal v-model="isAddTagModalShown">
      <add-tag
        @close="isAddTagModalShown = false"
        @added="newTagAdded"
      ></add-tag>
    </modal>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";

export default {
  name: "manage-property-tags",
  mixins: [vueGoodTable],
  components: {
    AddTag: () => import("@/views/managePropertyTags/add")
  },
  data() {
    return {
      isAddTagModalShown: false,

      propertyTagsTableColumns: [
        {
          label: "",
          field: "actions",
          tdClass: "text-center",
          sortable: false,
          width: "180px"
        },
        {
          label: "Name",
          field: "name"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      propertyTagsTableData: [],
      propertyTagsTablePagination: {},
      isFilterActive: false,
      filter: {
        referenceCode: "",
        "title[partial]": "",
        category: "",
        furnishingType: "",
        purpose: ""
      }
    };
  },
  mounted() {
    this.getPropertyTags({ limit: this.tableParams.perPage });
  },
  methods: {
    // Table related methods
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getPropertyTags();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getPropertyTags();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    openAddTag() {
      this.isAddTagModalShown = true;
    },
    newTagAdded() {
      this.isAddTagModalShown = false;
      this.getPropertyTags();
    },

    // ============================= API Related ===============================
    async getPropertyTags(queries = {}) {
      this.$store.commit("setIsLoading", true);
      let data = await this.$store.dispatch(
        "managePropertyTags/getAllPropertyTags",
        {
          ...queries,
          page: this.tableParams.page,
          limit: this.tableParams.perPage
        }
      );

      this.propertyTagsTableData = this._.cloneDeep(data.data);
      this.propertyTagsTablePagination = this._.cloneDeep(data.meta.pagination);
      this.$store.commit("setIsLoading", false);
    },
    async deletePropertyTags(name) {
      const c = await this.$confirm({
        type: "alert",
        title: "Delete tag",
        message: "Are you sure to delete this tag?"
      });

      if (c) {
        try {
          this.$store.commit("setIsLoading", true);
          await this.$store.dispatch(
            "managePropertyTags/deletePropertyTags",
            name
          );
          this.$notify({
            group: "alert",
            type: "success",
            title: "Success",
            text: `Tag ${name} has been deleted successfully.`
          });

          this.$store.commit("setIsLoading", false);
          this.getPropertyTags();
        } catch (error) {
          this.$store.commit("setIsLoading", false);
          this.$notify({
            group: "alert",
            type: "error",
            title: "Error",
            text: " An unexpected error occured. Please try again later."
          });
          throw error;
        }
      }
    }
  }
};
</script>

<style>
.manage-property-tags {
}
</style>
