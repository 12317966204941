var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"manage-property-tags animatedBox"},[_c('div',{staticClass:"container fluid"},[_vm._m(0),_c('div',{staticClass:"card"},[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.propertyTagsTableColumns,"rows":_vm.propertyTagsTableData,"totalRows":_vm.propertyTagsTablePagination.total,"pagination-options":{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: _vm.perPageOptions,
          dropdownAllowAll: false
        },"sort-options":{
          enabled: false
        }},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'actions')?_c('span',[_c('button',{staticClass:"btn danger",on:{"click":function($event){return _vm.deletePropertyTags(props.row.name)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptyarea"},slot:"emptyarea"},[_c('no-data',{attrs:{"message":"There is no data"}})],1),_c('div',{staticClass:"p-2",attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('button',{staticClass:"btn main mr-2",on:{"click":_vm.openAddTag}},[_c('i',{staticClass:"fas fa-plus"})]),_c('button',{staticClass:"btn toggle",class:{ active: _vm.isFilterActive },on:{"click":_vm.filterToggle}},[_c('i',{staticClass:"fas fa-filter"})])])])],1)]),_c('modal',{model:{value:(_vm.isAddTagModalShown),callback:function ($$v) {_vm.isAddTagModalShown=$$v},expression:"isAddTagModalShown"}},[_c('add-tag',{on:{"close":function($event){_vm.isAddTagModalShown = false},"added":_vm.newTagAdded}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card flat p-3 mb-2"},[_c('h3',{staticClass:"mb-1"},[_vm._v("Manage Tags")]),_c('p',[_vm._v("Manage recommended tags used in property & project")])])}]

export { render, staticRenderFns }